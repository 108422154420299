/* JavaScript for FilterButton */

import app from "../tfi_app";

// Export the class itself
export class TruncateList {
  constructor(element) {
    this.element = element;
    this.button = this.element.querySelector(".js-truncate-button");
    this.gridItems = this.element.querySelectorAll(".ca-rail__contents > *");
    this.state = {
      truncate: true,
    };

    this.button.addEventListener("click", () => {
      this.state.truncate = false;
      this.truncateList();
    });
  }

  truncateList() {
    if (this.state.truncate) {
      this.gridItems.forEach((element, index) => {
        if (index >= 4) {
          element.classList.add("hidden");
        }
      });
    } else {
      this.gridItems.forEach((element) => {
        element.classList.remove("hidden");
        this.button.classList.add("visually-hidden");
      });
    }
  }
}

// Exports an array of all the current instances
export const truncateList = {
  current: [],
};

// Export an init function that looks for and instantiates the module on pageload
export const init = () => {
  // Initialize any instances of the ImageGridModule on any given page
  app.addEventListener("pageLoad", (e) => {
    truncateList.current = [
      ...e.target.querySelectorAll(".js-ca-rail--truncate"),
    ].map((instance) => new TruncateList(instance));
  });
};
