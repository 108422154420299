/* eslint-disable no-use-before-define */
/* eslint-disable max-classes-per-file */
/* JavaScript for Menu Sub Menus */
import app from "../tfi_app";

export class Submenu {
  constructor() {
    this.submenus = document.querySelectorAll(".js-sub-menu");
    this.button = document.querySelector(".js-sub-menu--toggle");
    this.events = {};
    this.state = {
      previousSubMenu: null,
      currentSubMenu: null,
    };
    this.submenus.forEach((submenu) => {
      submenu.addEventListener("click", (event) => {
        this.toggleSubMenu(submenu, event);
      });
      submenu.addEventListener("keydown", (event) => {
        this.toggleSubMenu(submenu, event);
      });
    });
  }

  toggleSubMenu(submenu, data) {
    const isOpen = submenu === this.state.currentSubMenu;
    if (this.state.currentSubMenu) {
      this.state.previousSubMenu = this.state.currentSubMenu;
    }
    if (!isOpen) {
      this.expand(submenu);
      this.state.open = true;
      this.state.currentSubMenu = submenu;
    } else if (isOpen && data.type === "click") {
      this.collapse(submenu);
      this.state.open = false;
      this.state.currentSubMenu = null;
      this.state.previousSubMenu = null;
    }
  }

  expand(submenu) {
    submenu.classList.add("sub-menu--expanded");
    if (this.state.previousSubMenu) {
      this.state.previousSubMenu.classList.remove("sub-menu--expanded");
    }
    this.button.setAttribute("aria-expanded", true);
  }

  collapse(submenu) {
    submenu.classList.remove("sub-menu--expanded");
    this.button.setAttribute("aria-expanded", false);
  }

  on(name, handler) {
    if (!this.events[name]) {
      this.events[name] = [];
    }

    this.events[name].push(handler);
  }

  removeListener(name, listenerToRemove) {
    if (!this.events[name]) {
      return;
    }

    const filterListeners = (listener) => listener !== listenerToRemove;
    this.events[name] = this.events[name].filter(filterListeners);
  }

  emit(name, data) {
    if (!this.events[name]) {
      return;
    }

    this.events[name].forEach((handler) => handler(data));
  }
}

// Exports an array of all the current instances
export const submenu = {
  current: [],
};

// Export an init function that looks for and instantiates the module on pageload
export const init = () => {
  // Initialize any instances of the AccordionModule on any given page
  app.addEventListener("pageLoad", () => {
    submenu.current = [
      ...document.querySelectorAll(".js-sub-menu--toggle"),
    ].map((instance) => new Submenu(instance));
  });
};
