/* JavaScript for FilterButton */

import app from "../tfi_app";

const INCREMENT = 1;

// Export the class itself
export class LoadMore {
  constructor(element, scrollPos) {
    this.element = element;
    this.button = this.element;
    this.count = Number(this.element.dataset.count);
    this.params = new URLSearchParams(window.location.search);
    this.state = {
      limit: Number(this.params.get("page")) || INCREMENT,
      truncated: true,
    };

    // Onload, scroll the page to the previous position
    window.scrollTo(0, scrollPos);

    if (this.count === this.state.limit) {
      this.update({ truncated: false });
    }

    this.button.addEventListener("click", () => {
      if (this.state.limit + INCREMENT < this.count) {
        this.update({ limit: this.state.limit + INCREMENT });
      } else {
        this.update({ limit: this.count });
        this.update({ truncated: false });
      }
    });
  }

  update(update) {
    Object.assign(this.state, update);
    this.render(update);
  }

  render(update) {
    if (update.hasOwnProperty("limit")) {
      this.extendLimit();
    }
    if (update.hasOwnProperty("truncated")) {
      this.hideButton();
    }
  }

  // Set the new limit in the Search Params obj and trigger a reload
  extendLimit() {
    this.params.set("page", this.state.limit);
    const url = `${window.location.pathname}?${this.params
      .toString()
      .toLowerCase()}`;
    Turbolinks.clearCache();
    Turbolinks.visit(url);
    Turbolinks.scroll = {};
  }

  hideButton() {
    this.button.style.display = "none";
  }
}

// Exports an array of all the current instances
export const loadMore = {
  current: [],
};

const scrollPosition = {
  current: [],
};

// Export an init function that looks for and instantiates the module on pageload
export const init = () => {
  // Capture the pages scroll position before triggering a reload
  app.addEventListener("turbolinks:before-visit", () => {
    scrollPosition.current = window.scrollY;
  });

  // Pass the scroll position to the new instance
  app.addEventListener("pageLoad", (e) => {
    loadMore.current = [
      ...e.target.querySelectorAll(".js-load-more-button"),
    ].map((instance) => new LoadMore(instance, scrollPosition.current));
  });
};
