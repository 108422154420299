/* eslint-disable no-use-before-define */
/* eslint-disable no-underscore-dangle */
/* JavaScript for AudioModule */

import app from "../tfi_app";

export class AudioModule {
  constructor(element) {
    this.element = element;
    this.playPauseButton = this.element.querySelector(
      ".js-audio-player-play-pause-button"
    );
    this.playhead = this.element.querySelector(".js-audio-player-playhead");
    this.scrubber = this.element.querySelector(".js-audio-player-scrubber");
    this.progressBar = this.element.querySelector(
      ".js-audio-player-progress-bar"
    );
    this.timeRemainingLabel = this.element.querySelector(
      ".js-audio-player-time-remaining"
    );

    this.player = this.element.querySelector(".js-audio-player-player");

    // State
    this.state = {
      paused: true,
      progress: 0,
      duration: null,
      timeRemaining: null,
      timeElapsed: null,
      progressBarWidth: null,
      progressBarOffset: null,
      isScrubbing: false,
    };

    // Classes
    this.classes = {
      PAUSED: "audio-player--paused",
      PLAYING: "audio-player--playing",
    };

    // sets progress bar dimensions once css has had a chance to load
    setTimeout(() => {
      this.state.progressBarWidth = this.progressBar.offsetWidth;
      this.state.progressBarOffset = this.progressBar.offsetLeft;
    }, 500);

    // Initial render
    this._render(this.state);

    // Set duration when ready
    if (this.player.readyState > 0) {
      this._recordDuration();
    } else {
      this.player.addEventListener("loadedmetadata", () => {
        this._recordDuration();
      });
    }

    // State tracks the native player's state
    this.player.addEventListener("play", () => {
      this._update({ paused: false });
    });

    // State tracks the native player's state
    this.player.addEventListener("pause", () => {
      this._update({ paused: true });
    });

    // State tracks the native player's state
    this.player.addEventListener("timeupdate", () => {
      const progress = roundTo(
        this.player.currentTime / this.player.duration,
        4
      );
      const timeRemaining = this._getTimeRemaining();
      const timeElapsed = this._getTimeElapsed();

      this._update({
        progress,
        timeRemaining,
        timeElapsed,
      });
    });

    // clicking play pause toggles paused state
    this.playPauseHandler = this.togglePlayPause.bind(this);
    this.playPauseButton.addEventListener("click", this.playPauseHandler);

    // Pressing down on the pointer within the scrubber will start listening for scrub events
    this.pointerDownHandler = this._handleScrubStart.bind(this);
    this.scrubber.addEventListener("mousedown", this.pointerDownHandler);
    this.scrubber.addEventListener("touchstart", this.pointerDownHandler);

    // Simply releasing the mouse anywhere (like a click) will seek the audio, if the  mousedown started within the scrubber
    this.pointerUpHandler = this._handleScrubEnd.bind(this);
    document.addEventListener("mouseup", this.pointerUpHandler);
    document.addEventListener("touchend", this.pointerUpHandler);

    // Moving the pointer will scrub through the  audio
    this.pointerMoveHandler = this._handleScrubSeek.bind(this);
    document.addEventListener("mousemove", this.pointerMoveHandler);
    document.addEventListener("touchmove", this.pointerMoveHandler);

    // Navigating elsewhere destroys the instance
    $(document).one("turbo:before-cache", function () {
      this.destroy();
    });

    // Other components should be able to pause all present audio modules via AudioModule.pauseAll()
    this.globalPauseHandler = this.pause.bind(this);
    $(document).on("app:audio-player-pause", this.globalPauseHandler);
  }

  /*
  ::::::::::::::::::::::::
  :: CORE FUNCTIONALITY ::
  ::::::::::::::::::::::::
  */

  _update(update) {
    this.state = Object.assign(this.state, update);
    this._render(update);
  }

  _render(update) {
    if (update.hasOwnProperty("duration")) {
      this._renderDuration();
    }

    if (update.hasOwnProperty("paused")) {
      this._renderPlayPause();
    }

    if (update.hasOwnProperty("progress")) {
      this._setPlayheadPosition();
      this._renderTimeRemaining();
    }
  }

  /*
  :::::::::::::::::::
  :: STATE HELPERS ::
  :::::::::::::::::::
  */

  _recordDuration() {
    const duration = secondsToReadableTime(this.player.duration);
    this._update({ duration });
  }

  _getTimeRemaining() {
    const timeRemaining = secondsToReadableTime(
      this.player.duration - this.player.currentTime
    );
    return timeRemaining;
  }

  _getTimeElapsed() {
    const timeRemaining = secondsToReadableTime(this.player.currentTime);
    return timeRemaining;
  }

  // Safari does not support Pointer events, so we must use both `mouse` and `touch` events
  _handleScrubStart() {
    if (!this.player.readyState === 0) {
      return;
    }

    this._update({ isScrubbing: true });
  }

  // Safari does not support Pointer events, so we must use both `mouse` and `touch` events
  _handleScrubEnd(e) {
    if (this.state.isScrubbing) {
      let progress =
        (e.pageX - this.state.progressBarOffset) / this.state.progressBarWidth;
      progress = clamp(progress, 0, 1);
      const seekedTime = Math.round(progress * this.player.duration);
      this.player.currentTime = seekedTime;
    }
    this._update({ isScrubbing: false });
  }

  // Safari does not support Pointer events, so we must use both `mouse` and `touch` events
  _handleScrubSeek(e) {
    if (!this.state.isScrubbing || !this.player.readyState === 0) {
      return;
    }

    let progress =
      (e.pageX - this.state.progressBarOffset) / this.state.progressBarWidth;
    progress = clamp(progress, 0, 1);
    const seekedTime = Math.round(progress * this.player.duration);
    this.player.currentTime = seekedTime;
  }

  /*
  ::::::::::::::::::::
  :: RENDER HELPERS ::
  ::::::::::::::::::::
  */

  _renderPlayPause() {
    if (this.state.paused) {
      this.element.classList.remove(this.classes.PLAYING);
      this.element.classList.add(this.classes.PAUSED);
    } else {
      this.element.classList.remove(this.classes.PAUSED);
      this.element.classList.add(this.classes.PLAYING);
    }
  }

  _renderDuration() {
    if (!this.state.duration) {
      return;
    }
    this.timeRemainingLabel.innerText = this.state.duration;
  }

  _setPlayheadPosition() {
    const translation = `translateX(calc((${this.state.progressBarWidth}px - 100%) * ${this.state.progress}))`;
    this.playhead.style.transform = translation;
  }

  _renderTimeRemaining() {
    if (!this.state.timeRemaining) {
      return;
    }

    this.timeRemainingLabel.innerText = `${this.state.timeElapsed} / ${this.state.duration}`;
  }

  /*
  ::::::::::::::::::::
  :: PUBLIC METHODS ::
  ::::::::::::::::::::
  */

  play() {
    this.pauseAll();
    this.player.play();
  }

  pause() {
    this.player.pause();
  }

  togglePlayPause() {
    if (this.state.paused) {
      this.play();
    } else {
      this.pause();
    }
  }

  destroy() {
    this.pause();
    this.playPauseButton.removeEventListener("click", this.playPauseHandler);
    this.scrubber.removeEventListener("mousedown", this.pointerDownHandler);
    this.scrubber.removeEventListener("touchstart", this.pointerDownHandler);
    document.removeEventListener("mouseup", this.pointerUpHandler);
    document.removeEventListener("touchend", this.pointerUpHandler);
    document.removeEventListener("mousemove", this.pointerMoveHandler);
    document.removeEventListener("touchmove", this.pointerMoveHandler);
    $(document).off("app:audio-player-pause", this.globalPauseHandler);
  }

  pauseAll() {
    $(document).trigger("app:audio-player-pause");
  }
}

/*
:::::::::::::::
:: UTILITIES ::
:::::::::::::::
*/

function secondsToReadableTime(seconds) {
  const minutes = Math.floor(seconds / 60);
  seconds = Math.round(seconds % 60);
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }
  return [minutes, seconds].join(":");
}

function wrapInParens(str) {
  return `(${str})`;
}

function roundTo(input, numberOfDecimals) {
  return Math.round(input * 10 ** numberOfDecimals) / 10 ** numberOfDecimals;
}

function clamp(val, min, max) {
  return Math.min(Math.max(val, min), max);
}

export const audioModules = {
  current: [],
};

export const init = () => {
  // Initialize any instances of the AudioModule on any given page
  app.addEventListener("pageLoad", () => {
    audioModules.current = [
      ...document.querySelectorAll(".js-audio-player"),
    ].map((instance) => new AudioModule(instance));
  });
};
