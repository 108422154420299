import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.state.active = false;
    this.pathname = this.element.getAttribute("data-path").toLowerCase();
    this.params = new URLSearchParams(window.location.search);
    this.input = this.inputTarget.querySelector("input");
    this.button = this.inputTarget.querySelector(".search__button");
    this.input.value = this.params.get("query");
    this.revealButton();
  }

  revealButton() {
    if (this.input.value.length >= 1) {
      this.button.classList.add("search__button--active");
    } else {
      this.button.classList.remove("search__button--active");
    }
  }

  preventDefault(e) {
    e.preventDefault();
    // remove "search" param which is only used to display the search bar on the homepage
    this.params.delete("search");
    this.params.set("query", this.input.value);
    const url = `${this.pathname}?${this.params.toString().toLowerCase()}`;
    Turbolinks.clearCache();
    Turbolinks.visit(url);
  }

  close() {
    console.log("close");
  }

  state() {
    if (this.input.value && !this.state.active) {
      this.input.classList.add("input-container--active");
      this.state.active = true;
    } else if (!this.input.value && this.state.active) {
      this.input.classList.remove("input-container--active");
      this.state.active = false;
    }
  }
}
