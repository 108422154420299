export const resetFilters = (keepKey) => {
  const params = new URLSearchParams(window.location.search);
  const values = Array.from(params.keys());
  values.forEach((value) => {
    if (value !== keepKey) {
      params.delete(value);
    }
  });
  const url = `${window.location.pathname}?${params.toString().toLowerCase()}`;
  Turbolinks.clearCache();
  Turbolinks.visit(url);
};
