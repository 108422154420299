import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="form"
export default class extends Controller {
  connect() {
    this.filterType = this.element.getAttribute("data-param").toLowerCase();
    this.params = new URLSearchParams(window.location.search);
    this.select = this.element.querySelector("select");

    this.findSelected(this.filterType, this.params);
  }

  filter() {
    const select = this.element.querySelector("select");
    const { value } = select.options[select.selectedIndex];

    const newParam = value;
    this.params.set(this.filterType, newParam);
    const url = `${window.location.pathname}?${this.params
      .toString()
      .toLowerCase()}`;
    Turbolinks.clearCache();
    Turbolinks.visit(url);
  }

  findSelected(filterType, params) {
    const options = this.select.querySelectorAll("option");
    options.forEach((option) => {
      const targetValue = option.value;
      let hasMatch = null;
      if (params.get(filterType)) {
        hasMatch = targetValue === params.get(filterType).toLowerCase();
      }

      if (hasMatch) {
        this.select.value = targetValue;
      }
    });
  }
}
