/* JavaScript for VitrineModule */
import app from "../tfi_app";

// Export the class itself
export class BrowsingHistory {
  constructor(element) {
    this.element = element;
    this.params = new URLSearchParams(window.location.search);
    this.state = {
      showHistory: true,
    };
    if (this.params.get("clear_log")) {
      this.update({ showHistory: false });
    }
  }

  update(update) {
    Object.assign(this.state, update);
    this.render(update);
  }

  render(update) {
    if (update.hasOwnProperty("showHistory")) {
      if (!this.state.showHistory) {
        this.trimParam();
      }
    }
  }

  // Removes "clear_log" param from url
  trimParam() {
    this.params.delete("clear_log");
    const params = this.params.toString();
    const paramsString = params ? `?${this.params.toString()}` : "";
    const newSrc = `${window.location.pathname}${paramsString}`;
    window.location.href = newSrc;
  }
}

// Exports an array of all the current instances
export const browsingHistory = {
  current: [],
};

// Export an init function that looks for and instantiates the module on pageload
export const init = () => {
  // Initialize any instances of the VitrineModule on any given page
  app.addEventListener("pageLoad", (e) => {
    browsingHistory.current = [
      ...e.target.querySelectorAll(".js-browsing-history__container"),
    ].map((instance) => new BrowsingHistory(instance));
  });
};
