export const fadeOutElement = (element, options = {}) => {
  const { duration = 600, easing = "ease-in" } = options;
  return new Promise((resolve) => {
    // Set pre-transition state
    element.style.setProperty("display", "block");
    element.style.setProperty("opacity", 1);
    // Trigger a reflow so that we can then use a transition
    element.offsetWidth; // eslint-disable-line no-unused-expressions

    // Clean up after the transition ends
    const handler = () => {
      element.removeEventListener("transitionend", handler);
      element.style.setProperty("display", "none");
      resolve();
    };
    element.addEventListener("transitionend", handler);

    // Set the end state styles
    element.style.setProperty("transition", `opacity ${duration}ms ${easing}`);
    element.style.setProperty("opacity", 0);
  });
};

export const fadeInElement = (element, options = {}) => {
  const { duration = 600, easing = "ease-in" } = options;
  return new Promise((resolve) => {
    // Set pre-transition state
    element.style.setProperty("display", "block");
    element.style.setProperty("opacity", 0);

    // Trigger a reflow so that we can then use a transition
    element.offsetWidth; // eslint-disable-line no-unused-expressions

    // Clean up after the transition ends
    const handler = () => {
      element.removeEventListener("transitionend", handler);
      resolve();
    };
    element.addEventListener("transitionend", handler);

    // Set the end state styles
    element.style.setProperty("transition", `opacity ${duration}ms ${easing}`);
    element.style.setProperty("opacity", 1);
  });
};
