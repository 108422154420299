/* JavaScript for CarouselModule */

import app from "../tfi_app";
import { GenericFlickityCarousel } from "../components/generic_flickity_carousel";
import { CrossfadeCarousel } from "../components/crossfade_carousel";

// Export the class itself
export class CarouselModule extends GenericFlickityCarousel {
  static defaultConfig = Object.assign(
    {},
    GenericFlickityCarousel.defaultConfig,
    {
      cellAlign: "left",
      draggable: true,
      // lazy load images to make sure the carousel doesn't initiate before the images have
      // load two images at a time to accommodate the design
      lazyLoad: 2,
    }
  );

  constructor(element) {
    super(element);
    this.hasCaptionCarousel = element.querySelector(".js-carousel-captions");
    if (this.hasCaptionCarousel) {
      this.captionCarousel = new CrossfadeCarousel(
        element.querySelector(".js-carousel-captions"),
        {
          accessibility: false,
        }
      );
    }

    this.slideSizers = [...this.slides].map((slide) => {
      const sizer = slide.querySelector(".js-carousel-slide-sizer");
      const { naturalWidth } = sizer.dataset;
      const { naturalHeight } = sizer.dataset;
      return {
        slide,
        sizer,
        naturalWidth,
        naturalHeight,
      };
    });

    this.setSlideSizes();
    this.flickity.resize();
  }

  select(index) {
    super.select(index);
    if (this.hasCaptionCarousel) {
      this.captionCarousel.select(index);
    }
  }

  next() {
    super.next();
    if (this.hasCaptionCarousel) {
      this.captionCarousel.next();
    }
  }

  previous() {
    super.previous();
    if (this.hasCaptionCarousel) {
      this.captionCarousel.previous();
    }
  }

  destroy() {
    super.destroy();
    if (this.hasCaptionCarousel) {
      this.captionCarousel.destroy();
    }
  }

  refreshLayout() {
    this.setSlideSizes();
    this.flickity.resize();
    if (this.hasCaptionCarousel) {
      this.captionCarousel.refreshLayout();
    }
    this.trigger("layout");
  }

  setSlideSizes() {
    const carouselHeight =
      this.element.querySelector(".js-carousel-slide").offsetHeight;
    // Each slide's maximum width, as measured by a factor of the component's display width
    const isMobile = app.isMobile();
    const scaleFactor = isMobile ? 0.9 : 0.7;
    const maxWidth = window.innerWidth * scaleFactor;
    this.slideSizers.forEach(({ sizer, naturalWidth, naturalHeight }) => {
      const scaledWidth = naturalWidth * scaleFactor;
      const constrainWidth = scaledWidth > window.innerWidth;
      const constrainHeight = naturalHeight * scaleFactor > carouselHeight;

      let width;
      let height;

      if (!constrainHeight && constrainWidth) {
        width = `${maxWidth}px`;
        height = "auto";
      } else {
        width = "auto";
        height = "100%";
      }

      sizer.style.setProperty("width", `${width}`);
      sizer.style.setProperty("height", `${height}`);
    });
  }
}

// Exports an array of all the current instances
export const carouselModules = {
  current: [],
};

// Export an init function that looks for and instantiates the module on pageload
export const init = () => {
  // Initialize any instances of the CarouselModule on any given page
  app.addEventListener("pageLoad", (e) => {
    carouselModules.current = [
      ...e.target.querySelectorAll(".js-carousel-module"),
    ].map((instance) => new CarouselModule(instance));
  });
};
