/* JavaScript for LazyImages */

// import app from "../tfi_app";
import * as animations from "../utilities/animations";

// Export the class itself
export class LazyImages {
  constructor(element) {
    this.element = element;
    this.sentinel = element.querySelector(".js-sentinel");
    this.lowResImg = element.querySelector(".js-placeholder-image");
    this.highResImg = element.querySelector("picture img");
    this.state = {};
    this.state.inview = false;
    this.state.highResLoaded = false;

    const handleIntersect = (entries) => {
      entries.forEach((entry) => {
        // console.log(entry, entry.boundingClientRect.top, window.innerHeight);
        if (entry.boundingClientRect.top < window.innerHeight) {
          this.update({ inview: true });
        }
      });
    };

    const options = {
      root: null,
      rootMargin: "10px",
      threshold: 1.0,
    };

    this.observer = new IntersectionObserver(handleIntersect, options);
    this.observer.observe(this.sentinel);
  }

  update(update) {
    Object.assign(this.state, update);
    this.render(update);
  }

  render(update) {
    if (update.hasOwnProperty("inview")) {
      this.handleInview();
      this.destroy();
    }
    if (update.hasOwnProperty("highResLoaded")) {
      this.renderHighResImage();
    }
  }

  handleInview() {
    if (this.highResImg.complete) {
      this.update({
        highResLoaded: true,
      });
    } else {
      this.highResImg.addEventListener(
        "load",
        this.renderHighResImage.bind(this)
      );
    }
  }

  renderHighResImage() {
    this.highResImg.style.opacity = 1;
    animations.fadeOutElement(this.lowResImg, {
      duration: 150,
    });
  }

  destroy() {
    this.observer.disconnect();
  }
}
