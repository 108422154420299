/* JavaScript for AccordionModule */
import app from "../tfi_app";
import * as animations from "../utilities/animations";
import { isDescendant } from "../utilities";

import focusLock from "./focus_lock";

// Export the class itself
export class Header {
  constructor(element) {
    // Elements
    this.element = element;
    this.main = document.body;
    this.menu = this.element.querySelector(".js-menu");
    this.button = this.element.querySelector(".js-menu-button");
    this.primaryRow = this.element.querySelector(".js-site-header__row");
    this.search = this.element.querySelector(".js-site-header__search");
    this.searchButtons = this.search.querySelectorAll(".search-icon");
    this.searchInput = this.search.querySelector(".search-input");

    // Constants
    this.classes = {
      open: "--open",
      closed: "--closed",
      expanded: "--expanded",
      collapsed: "--collapsed",
      searchActive: "--active",
    };

    // State
    this.state = {};
    this.state.open = element.classList.contains(this.classes.open);
    this.state.expanded = true;
    this.state.search = false;

    const isSearchPage = window.location.pathname === "/search";
    if (isSearchPage) {
      this.update({ search: true });
    }

    // Events
    if (this.button) {
      this.button.addEventListener("click", () => {
        if (this.state.open) {
          this.update({ open: false });
        } else {
          this.update({ open: true });
        }
      });

      this.searchButtons.forEach((searchButton) => {
        searchButton.addEventListener("click", (e) => {
          if (this.state.search && isDescendant(searchButton, e.target)) {
            this.update({ search: false });
          } else {
            this.update({ search: true });
          }
        });
      });
    }

    document.addEventListener("click", (event) => {
      const insideHeader = isDescendant(this.primaryRow, event.target);
      if (!insideHeader) {
        this.update({ search: false });
      }
    });

    const handleIntersect = ([sentinel]) => {
      clearTimeout(this.snapIntoPlaceDelay);

      const { intersectionRatio } = sentinel;

      const distanceFromViewportTopEdge = sentinel.boundingClientRect.top;
      const isPassingThroughViewportTopEdge =
        distanceFromViewportTopEdge < app.state.windowHeight / 2;
      if (isPassingThroughViewportTopEdge) {
        return;
      }
      if (intersectionRatio <= 0 && this.state.expanded) {
        this.collapse();
      } else if (intersectionRatio > 0 && !this.state.expanded) {
        this.expand();
      }
    };
    // Sets threshold to include 100 steps in animation
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: Array.from({ length: 100 }).map((_, index) => index / 100),
    };
    this.observer = new IntersectionObserver(handleIntersect, options);
    this.observer.observe(this.element);
  }

  expand() {
    this.element.classList.add(`site-header${this.classes.expanded}`);
    this.element.classList.remove(`site-header${this.classes.collapsed}`);
    this.update({
      expanded: true,
    });
  }

  collapse() {
    this.element.classList.add(`site-header${this.classes.collapsed}`);
    this.element.classList.remove(`site-header${this.classes.expanded}`);
    this.update({
      expanded: false,
    });
  }

  update(update) {
    Object.assign(this.state, update);
    this.render(update);
  }

  render(update) {
    if (update.hasOwnProperty("open")) {
      if (this.state.open) {
        this.open();
      } else {
        this.close();
      }
    }

    if (update.hasOwnProperty("search")) {
      if (this.state.search) {
        this.openSearch();
      } else {
        this.closeSearch();
      }
    }
  }

  // Public methods
  open() {
    animations.fadeInElement(this.menu, { duration: 150 });
    this.element.classList.add(`menu${this.classes.open}`);
    this.element.classList.remove(`menu${this.classes.closed}`);
    this.element.classList.add("color-pink");
    this.main.classList.add(`modal${this.classes.open}`);
    this.button.ariaExpanded = true;
    this.setFocusLock();
  }

  close() {
    animations.fadeOutElement(this.menu, { duration: 150 });
    this.element.classList.remove(`menu${this.classes.open}`);
    this.element.classList.add(`menu${this.classes.closed}`);
    this.element.classList.remove("color-pink");
    this.main.classList.remove(`modal${this.classes.open}`);
    this.button.ariaExpanded = false;
    this.setFocusLock();
  }

  openSearch() {
    this.search.classList.add(
      `site-header__column-search${this.classes.searchActive}`
    );
    this.searchButtons.forEach((searchButton) => {
      searchButton.ariaExpanded = true;
    });
    this.searchInput.focus();
  }

  closeSearch() {
    // const searchButton = this.search.querySelector(".search-icon");
    this.search.classList.remove(
      `site-header__column-search${this.classes.searchActive}`
    );
    this.searchButtons.forEach((searchButton) => {
      searchButton.ariaExpanded = false;
    });
  }

  setFocusLock() {
    if (this.state.open) {
      this.unlockFocus = focusLock.lock({
        selectors: [".js-menu *", ".js-site-header__row *"],
        loopTarget: this.button,
      });
    } else {
      this.unlockFocus();
    }
  }
}

// Exports an array of all the current instances
export const header = {
  current: [],
};

// Export an init function that looks for and instantiates the module on pageload
export const init = () => {
  // Initialize any instances of the AccordionModule on any given page
  app.addEventListener("pageLoad", () => {
    header.current = [...document.querySelectorAll(".js-site-header")].map(
      (instance) => new Header(instance)
    );
  });
};
