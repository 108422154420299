/* eslint-disable max-classes-per-file */
/* JavaScript for Menu Sub Menus */
import app from "../tfi_app";

export class ParamLinks {
  constructor(element) {
    this.element = element;
    this.links = this.element.querySelectorAll("a");
    this.classRoot = this.element.getAttribute("data-class");
    this.classes = {
      selected: `${this.classRoot}--selected`,
    };

    this.links.forEach((link) => {
      const isDefault = link.getAttribute("data-default") === "true";
      const linkHasQuery = link.search !== "";
      const target = linkHasQuery ? link.pathname + link.search : link.pathname;
      const locationHasQuery = window.location.search !== "";
      const currentPath = locationHasQuery
        ? window.location.pathname + window.location.search
        : window.location.pathname;
      const isMatch = currentPath.includes(target) || currentPath === target;
      const isIndexPage = currentPath === target;
      // console.log(isDefault);
      if (isMatch && !isDefault) {
        link.classList.add(this.classes.selected);
      } else if (isMatch && isIndexPage && isDefault) {
        link.classList.add(this.classes.selected);
      }
    });
  }
}

// Exports an array of all the current instances
export const paramLinks = {
  current: [],
};

// Export an init function that looks for and instantiates the module on pageload
export const init = () => {
  // Initialize any instances of the AccordionModule on any given page
  app.addEventListener("pageLoad", () => {
    paramLinks.current = [...document.querySelectorAll(".js-param-links")].map(
      (instance) => new ParamLinks(instance)
    );
  });
};
