import Flickity from "flickity";
import { GenericCarousel } from "./generic_carousel";

export class GenericFlickityCarousel extends GenericCarousel {
  static selectorPrefix = "js-carousel";

  static sizedClassName = `${this.selectorPrefix}--sized`;

  static defaultConfig = Object.assign({}, GenericCarousel.defaultConfig, {
    wrapAround: false,
    draggable: false,
    prevNextButtons: false,
    pageDots: false,
  });

  constructor(element, options, elements) {
    super(element, options, elements);

    this.update({ sized: true });

    this.flickity.on("dragStart", () => {
      const changeHandler = (updatedIndex) => {
        this.select(updatedIndex, true);
        this.flickity.off("change", changeHandler);
      };
      this.flickity.on("change", changeHandler);
    });

    GenericFlickityCarousel.current.push(this);
  }

  get defaultState() {
    const parentDefaultState = super.defaultState;
    return Object.assign({}, parentDefaultState, {
      maxIndex: this.flickity.slides.length - 1,
      sized: false,
    });
  }

  beforeInit() {
    this.flickity = new Flickity(this.slideWrapper, this.config);
  }

  /* Public methods */
  select(index, skipFlickitySelect = false) {
    super.select(index);

    if (skipFlickitySelect) {
      return;
    }

    this.flickity.select(index);
  }

  next() {
    super.next();
    this.flickity.next();
  }

  previous() {
    super.previous();
    this.flickity.previous();
  }

  destroy() {
    super.destroy();
    GenericFlickityCarousel.current = GenericFlickityCarousel.current.filter(
      (c) => c !== this
    );
    this.flickity.destroy();
  }

  refreshLayout() {
    this.update({ sized: false });
    this.flickity.resize();
    this.update({ sized: true });
    this.trigger("layout");
  }

  render(update, previousState) {
    super.render(update, previousState);

    if (update.hasOwnProperty("sized")) {
      if (this.state.sized) {
        this.element.classList.add(this.constructor.sizedClassName);
      } else {
        this.element.classList.remove(this.constructor.sizedClassName);
      }
    }
  }

  /* Public helper methods */
  static getInstance(element) {
    return GenericCarousel.current.find((i) => {
      return i.element === element || i.flickity === element;
    });
  }
}

GenericFlickityCarousel.current = [];
