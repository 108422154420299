/* JavaScript for VideoGridModule */

import app from "../tfi_app";

// Export the class itself
export class VideoGridModule {
  constructor(element) {
    this.element = element;
    this.buttons = element.querySelectorAll(".js-video-grid-module__show-more");
    this.grid = element.querySelector(".js-video-grid__container");

    // Constants
    this.classes = {
      TRUNCATED: "video-grid__container--truncate",
      HIDDEN: "hidden",
    };

    // State
    this.state = {};
    this.state.truncated = this.grid.classList.contains(this.classes.TRUNCATED);

    // Events
    if (this.buttons) {
      this.buttons.forEach((button) => {
        button.addEventListener("click", () => {
          if (this.state.truncated) {
            this.showAll();
          }
        });
      });
    }
  }

  // Public methods

  update(update) {
    Object.assign(this.state, update);
    this.render(update);
  }

  render(update) {
    if (update.hasOwnProperty("truncated")) {
      if (!this.state.truncated) {
        this.grid.classList.remove(this.classes.TRUNCATED);
        this.buttons.forEach((button) => {
          button.classList.add(this.classes.HIDDEN);
        });
      }
    }
  }

  showAll() {
    this.update({ truncated: false });
  }
}

// Exports an array of all the current instances
export const videoGridModules = {
  current: [],
};

// Export an init function that looks for and instantiates the module on pageload
export const init = () => {
  // Initialize any instances of the VideoGridModule on any given page
  app.addEventListener("pageLoad", (e) => {
    videoGridModules.current = [
      ...e.target.querySelectorAll(".js-video-grid-module"),
    ].map((instance) => new VideoGridModule(instance));
  });
};
