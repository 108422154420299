/* JavaScript for FilterOptions */

// TODO //
// Build out multi-item filtering
// Priority should go to building out tfi_app and turbolinks
// before more work goes into filtering

import app from "../tfi_app";

// Export the class itself
export class FilterDropDown {
  constructor(element) {
    this.element = element;
    this.filters = this.element.querySelectorAll(".js-filter-drop-down");
    this.state = {
      activeFilter: null,
    };

    this.filters.forEach((filter, index) => {
      const button = filter.querySelector(".filter-button--title");
      button.addEventListener("click", (event) => {
        if (this.state.activeFilter !== index) {
          this.update({ activeFilter: index });
          event.preventDefault();
        } else {
          this.update({ activeFilter: null });
          event.preventDefault();
        }
      });
    });
  }

  update(update) {
    Object.assign(this.state, update);
    this.render(update);
  }

  render(update) {
    if (update.hasOwnProperty("activeFilter")) {
      this.openFilter();
    }
  }

  openFilter() {
    this.resetFilters();
    if (this.state.activeFilter === null) {
      return;
    }
    const activeFilter = this.filters[this.state.activeFilter];
    const button = activeFilter.querySelector(".filter-button--title");
    activeFilter.classList.add("filter-row--drop-down--active");
    button.ariaExpanded = "true";
    this.state.open = true;
  }

  resetFilters() {
    this.filters.forEach((filter) => {
      filter.classList.remove("filter-row--drop-down--active");
      const button = filter.querySelector(".filter-button--title");
      button.ariaExpanded = "false";
      this.state.open = false;
    });
  }
}

// Exports an array of all the current instances
export const filterDropDown = {
  current: [],
};

// Export an init function that looks for and instantiates the module on pageload
export const init = () => {
  // Initialize any instances of the ImageGridModule on any given page
  app.addEventListener("pageLoad", (e) => {
    filterDropDown.current = [
      ...e.target.querySelectorAll(".js-archive-filters"),
    ].map((instance) => new FilterDropDown(instance));
  });
};
