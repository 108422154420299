import * as Util from "../utilities";
import app from "../tfi_app";

/*
  This javascript provides an interface for Flickity to work in a
  Linked by Air type of way. It includes:
  - accessible buttons
  - screen reader announcements
  - turbolinks cacheing behavior

  This js should handle all of the default functionality for carousels.
  If you need to interact with a specific instance, you can either crawl
  the `GenericCarousel.current` array, or you can use the static
  `getInstance` method. Otherwise, we provide static methods for performing
  actions on all active carousels.

  Example:
    import { GenericCarousel } from "../scripts/generic_carousel";
    GenericCarousel.current.forEach(c => c.next());

  Example:
    import { GenericCarousel } from "../scripts/generic_carousel";
    const element = document.getElementById('some-id')
    const carousel = GenericCarousel.getInstance(element);
    carousel.refreshLayout();

  Example:
    import { GenericCarousel } from "../scripts/generic_carousel";
    GenericCarousel.refreshAll();

  You may extend this class to provide custom functionality or controls.
  By default, instances of class extensions will still accumulate in the
  GenericCarousel.current array.

  Certain elements are needed to build out functionality. You may pass
  them in via the `elements` argument, or use the default CSS class names.
  If using the latter path, make sure you elements exist within element.
  If the elements are found, functionality will be added. In that sense,
  they are all optional, but the you ought to include them for accessibility.
  See the `elements` option below.

  @param  {HTMLNode}                     element   The containing element
  @param  {object}                       options   Any options that should be passed to Flickity
  @param  {GenericCarousel ~ Elements}   elements  Named references to elements that build out carousel functionality

  @typedef {Object} GenericCarousel ~ Elements
  @property   {HTMLNode}    slideWrapper=".js-carousel-slide-wrapper"       The element that contains all the slides, which will be the target of the flickity initialization
  @property   {HTMLNode}    [previousControl=".js-carousel-previous"]       A button to select the carousel's previous slide
  @property   {HTMLNode}    [nextControl=".js-carousel-next"]               A button to select the carousel's next slide
*/

export class GenericCarousel {
  static current = [];

  static selectorPrefix = "js-carousel";

  static defaultConfig = {
    wrapAround: true,
    accessibility: true,
  };

  constructor(element, options = {}, elements = {}) {
    // CONFIG
    this.config = Object.assign(this.constructor.defaultConfig, options);

    // ELEMENTS
    this.setElements(element, elements);

    // ACCESSIBILITY CONCERNS
    this.setupAccessibility();

    // PRE-INITIALIZE HOOKS
    this.beforeInit();

    // STATE
    this.state = this.defaultState;

    // EVENTS
    this.setUpEvents();

    // INITIAL RENDER
    this.update(this.state);

    // KEEP TRACK OF IT
    GenericCarousel.current.push(this);

    // HOOKS
    this.trigger("initialize");
  }

  /* Constructor helpers (broken out so they can be overwritten) */
  setElements(element, elements = {}) {
    this.element = element;
    this.slideWrapper =
      elements.slideWrapper ||
      element.querySelector(
        `.${this.constructor.selectorPrefix}-slide-wrapper`
      ) ||
      element;
    this.slides =
      elements.slides ||
      element.querySelectorAll(`.${this.constructor.selectorPrefix}-slide`);
    // Convert from nodeList to Array
    this.slides = [...this.slides];
    this.previousControl =
      elements.previousControl ||
      element.querySelector(`.${this.constructor.selectorPrefix}-previous`) ||
      element.querySelector(".js-previous-control");
    this.nextControl =
      elements.nextControl ||
      element.querySelector(`.${this.constructor.selectorPrefix}-next`) ||
      element.querySelector(".js-next-control");
  }

  get defaultState() {
    return {
      index: 0,
      maxIndex: this.slides.length - 1,
      canGoNext: true,
      canGoPrevious: this.config.wrapAround,
    };
  }

  setupAccessibility() {
    if (!this.config.accessibility) {
      return;
    }

    // Announcements
    this.announcements = document.createElement("div");
    this.announcements.className = "visually-hidden js-carousel-announcements";
    this.announcements.setAttribute("aria-live", "polite");
    this.element.appendChild(this.announcements);

    // Control labels
    if (this.previousControl) {
      this.previousLabel = document.createElement("div");
      this.previousLabel.className = "visually-hidden";
      this.previousControl.appendChild(this.previousLabel);
    }

    if (this.nextControl) {
      this.nextLabel = document.createElement("div");
      this.nextLabel.className = "visually-hidden";
      this.nextControl.appendChild(this.nextLabel);
    }
  }

  beforeInit() {
    // Leaving blank so it can overwritten
  }

  setUpEvents() {
    if (this.nextControl) {
      this.nextControl.addEventListener("click", () => {
        this.next();
      });
    }
    if (this.previousControl) {
      this.previousControl.addEventListener("click", () => {
        this.previous();
      });
    }

    // Allow for event tie-ins
    this.events = {};
    this.events.initialize = [];
    this.events.layout = [];
    this.events.change = [];
  }

  /* Public methods */
  select(index) {
    const currentIndex = this.state.index;
    this.update({ index });
    this.updateControls();
    this.trigger("change", {
      index: this.state.index,
      previousIndex: currentIndex,
    });
  }

  next() {
    const currentIndex = this.state.index;
    const { nextIndex } = this;
    const shouldChange = typeof nextIndex === "number";
    if (shouldChange) {
      this.update({
        index: nextIndex,
      });
      this.updateControls();
      this.trigger("change", {
        index: this.state.index,
        previousIndex: currentIndex,
      });
    }
  }

  previous() {
    const currentIndex = this.state.index;
    const { previousIndex } = this;
    const shouldChange = typeof previousIndex === "number";
    if (shouldChange) {
      this.update({
        index: previousIndex,
      });
      this.updateControls();
      this.trigger("change", {
        index: this.state.index,
        previousIndex: currentIndex,
      });
    }
  }

  destroy() {
    GenericCarousel.current = GenericCarousel.current.filter((c) => c !== this);
  }

  refreshLayout() {
    this.trigger("layout");
  }

  on(event, handler) {
    if (!this.events[event]) {
      this.events[event] = [];
    }
    this.events[event].push(handler);

    return this.off.bind(this, event, handler);
  }

  off(event, handler) {
    this.events[event] = this.events[event].filter((e) => e !== handler);
  }

  trigger(event, args = {}) {
    if (!this.events[event]) {
      return;
    }

    const formattedArgs = Object.assign({}, args, {
      target: this,
    });
    this.events[event].forEach((cb) => cb.call(this, formattedArgs));
  }

  afterImagesLoad() {
    const images = this.element.querySelectorAll("img");
    if (!images) {
      return Promise.resolve();
    }

    const promises = [...images].map((imageElement) => {
      return new Promise((resolve, reject) => {
        if (imageElement.complete) {
          resolve();
        } else {
          /* eslint-disable no-param-reassign */
          imageElement.onload = resolve;
          imageElement.onerror = reject;
          /* eslint-enable no-param-reassign */
        }
      });
    });
    return Promise.allSettled(promises);
  }

  /* Public helper methods */
  static getInstance(element) {
    return GenericCarousel.current.find((i) => {
      return i.element === element;
    });
  }

  static refreshAll() {
    GenericCarousel.current.forEach((i) => i.refreshLayout());
  }

  static destroyAll() {
    GenericCarousel.current.forEach((i) => i.destroy());
  }

  /* State management functions */
  update(update) {
    const previousState = Object.assign({}, this.state);
    Object.assign(this.state, update);
    this.render(update, previousState);
    return previousState;
  }

  updateControls() {
    const canGoPrevious = typeof this.previousIndex === "number";
    const canGoNext = typeof this.nextIndex === "number";
    this.update({ canGoPrevious, canGoNext });
  }

  /* Render functions */
  /* eslint-disable no-unused-vars */
  render(update, previousState) {
    if (update.hasOwnProperty("canGoPrevious")) {
      this.renderPreviousControl();
    }
    if (update.hasOwnProperty("canGoNext")) {
      this.renderNextControl();
    }
    if (update.hasOwnProperty("index")) {
      this.renderAnnouncements();
    }
  }
  /* eslint-enable no-unused-vars */

  renderPreviousControl() {
    if (!this.previousControl) {
      return;
    }
    if (this.state.canGoPrevious) {
      this.previousControl.removeAttribute("disabled");
    } else {
      this.previousControl.setAttribute("disabled", "");
    }

    if (this.previousLabel) {
      this.previousLabel.innerHTML = `Go to slide #${this.previousIndex + 1}`;
    }
  }

  renderNextControl() {
    if (!this.nextControl) {
      return;
    }
    if (this.state.canGoNext) {
      this.nextControl.removeAttribute("disabled");
    } else {
      this.nextControl.setAttribute("disabled", "");
    }

    if (this.nextLabel) {
      this.nextLabel.innerHTML = `Go to slide #${this.nextIndex + 1}`;
    }
  }

  renderAnnouncements() {
    if (!this.announcements) {
      return;
    }
    this.announcements.innerHTML = `Now viewing slide #${
      this.state.index + 1
    } of ${this.state.maxIndex + 1}`;
  }

  /* Helper functions */
  get previousIndex() {
    if (this.state.maxIndex === 0) {
      return false;
    }
    if (this.config.wrapAround) {
      return Util.loopAround(this.state.index - 1, 0, this.state.maxIndex);
    }
    if (this.state.index === 0) {
      return false;
    }
    return this.state.index - 1;
  }

  get nextIndex() {
    if (this.state.maxIndex === 0) {
      return false;
    }
    if (this.config.wrapAround && this.state.maxIndex > 0) {
      return Util.loopAround(this.state.index + 1, 0, this.state.maxIndex);
    }
    if (this.state.index === this.state.maxIndex) {
      return false;
    }
    return this.state.index + 1;
  }
}

// // NOTE: we give these listeners a name so that they don't accumulate
app.addEventListener("turbolinks:before-cache", {
  name: "carousel-destroyer",
  handler: () => {
    GenericCarousel.destroyAll();
  },
});

app.addEventListener("resize", {
  name: "carousel-resizer",
  handler: () => {
    GenericCarousel.refreshAll();
  },
});
