import { Controller } from "@hotwired/stimulus";
import { LazyImages } from "../components/lazy_images";

export default class extends Controller {
  static targets = ["button"];

  connect() {
    this.nextPageFrame = this.element.querySelector(".next-page__results");
    this.lazy_load();
  }

  has_param(param) {
    return param !== undefined;
  }

  load_more_search(e) {
    const { params, nextPage, recordType } = this.nextPageFrame.dataset;
    const frameParams = JSON.parse(params);
    const button = e.target;
    const src = `/digital-archive/${recordType}?`;
    this.params = new URLSearchParams(src);
    if (this.has_param(frameParams.search)) {
      this.params.set("search", true);
      this.params.set("query", frameParams.query);
      this.params.set("page", nextPage);
      this.params.set("sample", true);
      this.params.set("hide_header", true);
    }
    const newSrc = decodeURIComponent(`${this.params}`);
    this.reset_src(button, newSrc);
  }

  load_more_related(e) {
    if (!this.nextPageFrame) {
      this.nextPageFrame = this.element.querySelector(".next-page__results");
    }

    const { params, nextPage, recordType } = this.nextPageFrame.dataset;
    const frameParams = JSON.parse(params);
    const button = e.target;
    const src = `/digital-archive/${recordType}?`;
    this.params = new URLSearchParams(src);
    if (this.has_param(frameParams.related)) {
      this.params.set("related", true);
      this.params.set("record_id", frameParams.record_id);
      this.params.set("record_type", frameParams.record_type);
      this.params.set("page", nextPage);
      this.params.set("hide_header", true);
    }
    const newSrc = decodeURIComponent(`${this.params}`);
    this.reset_src(button, newSrc);
  }

  // Formats filter params to include in turbo frames source
  // ex "subject=activism"
  format_filters(object) {
    const entries = Object.entries(object);
    return entries
      .map(([key, val]) => {
        return `${key}=${val}`;
      })
      .join("&");
  }

  load_more(e) {
    const { params, nextPage, recordType } = this.nextPageFrame.dataset;
    const jsonParams = JSON.parse(params);
    let filterParams = "";
    // If we have filter params, format them for url
    if (Object.keys(jsonParams).length) {
      filterParams = this.format_filters(jsonParams);
    }
    const button = e.target;
    const page = nextPage;
    const newSrc = `/digital-archive/${recordType}?page=${page}&hide_header=true&${filterParams}`;
    this.reset_src(button, newSrc);
  }

  reset_src(button, path) {
    button.remove();
    this.nextPageFrame.classList.remove("visually-hidden");
    this.nextPageFrame.setAttribute("src", path);
  }

  lazy_load() {
    this.highResImages = this.element.querySelectorAll(".js-lazy-image");

    this.lazyImages = [...this.highResImages].map(
      (images) => new LazyImages(images)
    );
  }
}
