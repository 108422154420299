/* JavaScript for VitrineModule */
import app from "../tfi_app";

const ACTIVE_CLASS = "vitrine-module__grid-item--active";

// Export the class itself
export class VitrineModule {
  constructor(element) {
    this.element = element;
    this.slides = this.element.querySelectorAll(".vitrine-module__grid-item");
    this.state = {
      activeSlide: null,
    };

    const isMobile = app.isMobile();

    this.slides.forEach((slide) => {
      if (!isMobile) {
        slide.addEventListener("mouseover", () => {
          this.setActiveSlide(slide);
        });
        slide.addEventListener("mouseout", () => {
          this.setActiveSlide(slide);
        });
      } else {
        slide.addEventListener("click", () => {
          this.setActiveSlide(slide);
        });
      }
    });
  }

  setActiveSlide(slide) {
    if (this.state.activeSlide !== slide) {
      this.update({ activeSlide: slide });
    } else {
      this.update({ activeSlide: null });
    }
  }

  update(update) {
    Object.assign(this.state, update);
    this.render(update);
  }

  render(update) {
    if (update.hasOwnProperty("activeSlide")) {
      this.slides.forEach((slide) => {
        slide.classList.remove(ACTIVE_CLASS);
      });
      if (this.state.activeSlide) {
        this.state.activeSlide.classList.add(ACTIVE_CLASS);
      }
    }
  }
}

// Exports an array of all the current instances
export const vitrineModules = {
  current: [],
};

// Export an init function that looks for and instantiates the module on pageload
export const init = () => {
  // Initialize any instances of the VitrineModule on any given page
  app.addEventListener("pageLoad", (e) => {
    vitrineModules.current = [
      ...e.target.querySelectorAll(".js-vitrine-module"),
    ].map((instance) => new VitrineModule(instance));
  });
};
