/* JavaScript for VideoPlayer */

import Plyr from "plyr";
import app from "../tfi_app";

const DEFAULT_CONFIG = {
  autoplay: true,
  autopause: true,
  muted: false,
};

// Export the class itself
export class VideoPlayer {
  constructor(element) {
    this.element = element;
    this.initButton = this.element.querySelector(".js-plyr__button");
    this.video = this.element.querySelector(".js-plyr__video");
    this.poster = this.element.querySelector(".js-plyr__poster");
    const config = Object.assign({}, DEFAULT_CONFIG);
    config.ratio = "16:9";
    this.initButton.addEventListener("click", () => {
      this.poster.classList.add("visually-hidden");
      this.video.classList.remove("visually-hidden");
      this.player = new Plyr(this.video, config);
      this.player.play();
    });
  }
}

// Exports an array of all the current instances
export const videoPlayer = {
  current: [],
};

// Export an init function that looks for and instantiates the module on pageload
export const init = () => {
  // Initialize any instances of the ImageGridModule on any given page
  app.addEventListener("pageLoad", (e) => {
    videoPlayer.current = [
      ...e.target.querySelectorAll(".js-plyr__container"),
    ].map((instance) => new VideoPlayer(instance));
  });
};
