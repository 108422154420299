import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.replayWeb = this.element.querySelector("replay-web-page");
    // Sometimes when arriving to the archived website page by navigating through the site the iframe doesn't load
    // In these cases we should trigger a hard refresh
    this.timeout = setTimeout(() => {
      if (
        this.replayWeb.shadowRoot &&
        this.replayWeb.shadowRoot.childNodes &&
        this.replayWeb.shadowRoot.childNodes.length
      ) {
        const nodes = Array.prototype.slice.call(
          this.replayWeb.shadowRoot.childNodes,
          0
        );
        this.hasIframe = nodes.filter((node) => {
          return node.nodeName === "IFRAME";
        });
        if (!this.hasIframe.length) {
          location.reload();
        }
      } else {
        location.reload();
      }
    }, 300);
  }
}
