/* JavaScript for FilterButton */

import app from "../tfi_app";
import * as urlSearchParams from "../utilities/url_search_params";

// Export the class itself
export class FilterButton {
  constructor(element) {
    this.element = element;
    const button = this.element;
    this.target = this.element.dataset.target;
    // this.params = new URLSearchParams(window.location.search);

    if (window.location.hash === `#${this.target}`) {
      button.classList.add("filter-button--selected");
    }

    if (window.location.pathname.search(this.target) >= 0) {
      button.classList.add("filter-button--selected");
    }

    button.addEventListener("click", (e) => {
      if (e.target.classList.contains("js-filter-button--clear")) {
        urlSearchParams.resetFilters("record_type");
      }
    });
  }
}

// Exports an array of all the current instances
export const filterButton = {
  current: [],
};

// Export an init function that looks for and instantiates the module on pageload
export const init = () => {
  // Initialize any instances of the ImageGridModule on any given page
  app.addEventListener("pageLoad", (e) => {
    filterButton.current = [
      ...e.target.querySelectorAll(".js-filter-button"),
    ].map((instance) => new FilterButton(instance));
  });
};
