/* JavaScript for LazyImages */

import app from "../tfi_app";
import { getFirstKeyboardFocusableElement } from "../utilities";

const LEFT_PADDING = 300;

// Export the class itself
export class MediaStack {
  constructor(element) {
    this.element = element;
    this.slides = this.element.querySelectorAll(".slide");
    this.slideImages = this.element.querySelectorAll(".js-slide-image");
    this.matte = this.element.querySelector(".media-stack-matte");
    this.modal = this.element.querySelector(
      ".js-collection-stack__mobile-modal"
    );
    this.enterStackButton = this.element.querySelector(".js-enter-stack");

    this.state = {
      activeSlide: null,
      hoverImg: null,
      activeImg: null,
      leftOffset: null,
      leftTranslate: null,
      stackTabbable: false,
    };

    this.slides.forEach((slide, index) => {
      slide.addEventListener("mouseover", () => {
        this.update({ hoverImg: index });
      });

      slide.addEventListener("click", (e) => {
        const slideImage = slide.querySelector(".js-slide-image");
        const button = slide.querySelector(".js-image-card-close");
        const activeSlide = this.slides[this.state.activeImg];
        const clickedOutsideSlide = !this.isDescendant(activeSlide, e.target);
        if (
          this.isDescendant(slideImage, e.target) ||
          slideImage === e.target
        ) {
          this.update({ activeImg: index });
        } else if (this.isDescendant(button, e.target) || clickedOutsideSlide) {
          this.update({ activeImg: null });
          this.update({ hoverImg: null });
          // Once the close button is clicked we should wait until
          // the slides have reset before allowing interactions again
          this.element.style.pointerEvents = "none";
          setTimeout(() => {
            this.element.style.pointerEvents = "all";
          }, 1000);
        }
      });
    });

    this.slideImages.forEach((image) => {
      // Clicking on an active image should result in a link to the record
      image.addEventListener("click", () => {
        if (image === this.activeImg) {
          window.location = image.dataset.linkUrl;
        }
      });
    });

    this.modal.addEventListener("click", (e) => {
      const button = this.modal.querySelector(".js-image-card-close");
      // Check if e.target is a childnode of close button
      // if so, reset state
      if (this.isDescendant(button, e.target)) {
        this.update({ activeImg: null });
        this.update({ hoverImg: null });
      }

      const slideImage = this.modal.querySelector(".js-slide-image");
      if (this.isDescendant(slideImage, e.target) || slideImage === e.target) {
        window.location = slideImage.dataset.linkUrl;
      }
    });

    // Reset when mouse leaves frame
    this.matte.addEventListener("mouseout", () => {
      this.update({ hoverImg: null });
    });

    // Reset when clicks fall outside slides
    this.matte.addEventListener("click", () => {
      this.update({ activeImg: null });
      this.update({ hoverImg: null });
    });

    this.enterStackButton.addEventListener("focus", () => {
      this.element.querySelectorAll("a, button").forEach((item) => {
        item.tabIndex = "-1";
      });
      // this.update({ stackTabbable: true });
      // this.firstElement = getFirstKeyboardFocusableElement(this.element);
      // this.firstElement.focus();
    });

    // Allow users using keyboard enter the stack
    this.enterStackButton.addEventListener("click", () => {
      this.element.querySelectorAll("a, button").forEach((item) => {
        item.tabIndex = "0";
      });
      this.update({ stackTabbable: true });
      // this.firstElement = getFirstKeyboardFocusableElement(this.element);
      // this.firstElement.focus();
    });
  }

  isDescendant(parent, child) {
    let node = child.parentNode;
    while (node != null) {
      if (node === parent) {
        return true;
      }
      node = node.parentNode;
    }
    return false;
  }

  isButton(element) {
    element.classList.contains("js-image-card-close");
  }

  update(update) {
    Object.assign(this.state, update);
    this.render(update);
  }

  render(update) {
    if (update.hasOwnProperty("hoverImg")) {
      this.translateImages();
    }

    if (update.hasOwnProperty("activeImg")) {
      if (app.isTablet()) {
        this.renderMobileModal();
      } else {
        this.highlightImage();
      }
    }

    if (update.hasOwnProperty("leftOffset")) {
      this.getLeftOffset();
    }

    if (update.hasOwnProperty("leftTranslate")) {
      this.translateLeft();
    }

    if (update.hasOwnProperty("stackTabbable")) {
      this.focusFirstElement();
    }
  }

  // Translate images on the condition that there is no active image
  translateImages() {
    if (this.state.hoverImg === null && this.state.activeImg === null) {
      this.resetImages();
      return;
    }
    if (this.state.activeImg !== null) {
      return;
    }
    // To expose a hovered image, translate images to the right in a block
    this.hoverImg = this.slides[this.state.hoverImg];
    if (this.hoverImg) {
      const imageWidth = this.hoverImg.offsetWidth / 2;

      this.slides.forEach((image, index) => {
        image.style.transform = `translateX(0)`;
        if (index > this.state.hoverImg) {
          image.style.transform = `translateX(${imageWidth}px)`;
        }
      });
    }
  }

  highlightImage() {
    if (this.state.activeImg === null) {
      this.resetImages();
      return;
    }

    this.activeSlide = this.slides[this.state.activeImg];
    this.activeImg = this.activeSlide.querySelector("img");
    this.activeCard = this.activeSlide.querySelector(".image-card");

    // This could be edited to be more specific to the selected media
    const centerline = window.innerWidth / 2;

    this.slides.forEach((slide, index) => {
      // Hide all image cards and reset transform
      slide.querySelector(".image-card").style.opacity = 0;
      slide.querySelector(".js-image-card-close").style.opacity = 0;
      slide.style.transform = 0;
      if (index === this.state.activeImg) {
        // If left edge of selected image is past centerline, align to center
        if (this.activeSlide.offsetLeft >= centerline) {
          const newOffset = centerline - this.activeSlide.offsetLeft;
          slide.style.transform = `translateX(${newOffset}px)`;
          this.update({ leftOffset: centerline });
        }
        // If left edge is before centerline, edge forward relative to position
        else {
          const imageWidth = this.activeImg.offsetWidth;
          const newOffset = imageWidth * 0.25;
          slide.style.transform = `translateX(${newOffset}px)`;
          this.update({ leftOffset: this.activeSlide.offsetLeft + newOffset });
        }
        this.activeCard.style.opacity = 1;
        slide.querySelector(".js-image-card-close").style.opacity = 1;
      } else if (index > this.state.activeImg) {
        this.translateRight(slide);
      }
    });
  }

  translateRight(slide) {
    const newOffset = this.activeSlide.offsetWidth + 100;
    slide.style.transform = `translateX(${newOffset}px)`;
  }

  getLeftOffset() {
    const offsets = [];
    this.slides.forEach((slide, index) => {
      if (index < this.state.activeImg) {
        const img = slide.querySelector("img");
        const boundary = this.state.leftOffset;
        // calculate offset position of the slides right edge
        const rightOffset = slide.offsetLeft + img.offsetWidth;
        // calculate the new offset position relative to starting offset
        const newRightOffset = boundary - rightOffset - LEFT_PADDING;
        offsets.push(newRightOffset);
        // update translate value after iterating
        if (offsets.length === this.state.activeImg) {
          this.update({ leftTranslate: Math.min(...offsets) });
        }
        if (rightOffset > boundary) {
          slide.style.transform = `translateX(${newRightOffset}px)`;
        }
      }
    });
  }

  translateLeft() {
    this.slides.forEach((slide, index) => {
      if (index < this.state.activeImg) {
        slide.style.transform = `translateX(${this.state.leftTranslate}px)`;
      }
    });
  }

  resetImages() {
    this.slides.forEach((image) => {
      image.style.transform = "translateX(0)";
      image.querySelector(".image-card").style.opacity = 0;
      image.querySelector(".js-image-card-close").style.opacity = 0;
    });
  }

  resetMobileModal() {
    this.modal.classList.remove("collection-stack__mobile-modal--active");
    this.modal.removeChild(this.modal.firstChild);
  }

  renderMobileModal() {
    if (this.state.activeImg === null) {
      this.resetMobileModal();
      return;
    }
    this.activeSlide = this.slides[this.state.activeImg];
    const clone = this.activeSlide.cloneNode(true);
    clone.style.transform = "translateX(0)";
    clone.style.height = "100%";
    this.modal.classList.add("collection-stack__mobile-modal--active");
    this.modal.appendChild(clone);
  }

  focusFirstElement() {
    this.firstElement = getFirstKeyboardFocusableElement(this.slides[0]);
    this.firstElement.focus();
  }

  destroy() {
    this.observer.disconnect();
  }
}

// Exports an array of all the current instances
export const mediaStack = {
  current: [],
};

// Export an init function that looks for and instantiates the module on pageload
export const init = () => {
  // Initialize any instances of the ImageGridModule on any given page
  app.addEventListener("pageLoad", (e) => {
    mediaStack.current = [...e.target.querySelectorAll(".js-media-stack")].map(
      (instance) => new MediaStack(instance)
    );
  });
};
