/* eslint-disable import/first */
// BEGIN MAGIC_MODULE_IMPORT

import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

// Stimulus Init
window.Stimulus = Application.start();
const context = require.context("./controllers", true, /\.js$/);
Stimulus.load(definitionsFromContext(context));

import * as carouselModule from "./magic_modules/carousel_module";
import * as videoModule from "./magic_modules/video_module";
import * as videoGridModule from "./magic_modules/video_grid_module";
import * as vitrineModule from "./magic_modules/vitrine_module";
import * as digitalExhibitionsModule from "./magic_modules/digital_exhibitions_module";

import * as homepageArchiveModule from './magic_modules/homepage_archive_module';
import * as pressModule from './magic_modules/press_module';
// END MAGIC_MODULE_IMPORT

// BEGIN MAGIC_MODULE_INIT

carouselModule.init();
videoModule.init();
videoGridModule.init();
vitrineModule.init();

digitalExhibitionsModule.init();
homepageArchiveModule.init();
pressModule.init();
// END MAGIC_MODULE_INIT

import * as accordion from "./components/accordion";
import * as audioModule from "./components/audio_module";
import * as filterButton from "./components/filter_button";
import * as filterDropDown from "./components/filter_drop_down";
import * as header from "./components/header";
// import * as lazyImages from "./components/lazy_images";
import * as loadMore from "./components/load_more";
import * as paramLinks from "./components/param_links";
import * as submenu from "./components/sub_menu";
import * as truncateList from "./components/truncate_list";
import * as videoPlayer from "./components/video_player";
import * as mediaStack from "./components/media_stack";
import * as preventScroll from "./components/prevent_scroll_link_handler";
import * as browsingHistory from "./components/browsing_history";

audioModule.init();
accordion.init();
filterButton.init();
filterDropDown.init();
header.init();
paramLinks.init();
// lazyImages.init();
loadMore.init();
submenu.init();
truncateList.init();
videoPlayer.init();
mediaStack.init();
preventScroll.init();
browsingHistory.init();
