/* JavaScript for FilterButton */

import app from "../tfi_app";

// Export the class itself
export class PreventScroll {
  constructor(element, scrollPos) {
    this.element = element;
    this.params = new URLSearchParams(window.location.search);
    // Onload, scroll the page to the previous position
    window.scrollTo(0, scrollPos);

    this.element.addEventListener("click", () => {
      this.triggerPageReload();
    });
  }

  // Set the new limit in the Search Params obj and trigger a reload
  triggerPageReload() {
    // this.params.set("page", this.state.limit);
    const url = `${window.location.pathname}?${this.params
      .toString()
      .toLowerCase()}`;
    Turbolinks.clearCache();
    Turbolinks.visit(url);
    Turbolinks.scroll = {};
  }
}

// Exports an array of all the current instances
export const preventScroll = {
  current: [],
};

const scrollPosition = {
  current: [],
};

// Export an init function that looks for and instantiates the module on pageload
export const init = () => {
  // Capture the pages scroll position before triggering a reload
  app.addEventListener("turbolinks:before-visit", () => {
    scrollPosition.current = window.scrollY;
  });

  // Pass the scroll position to the new instance
  app.addEventListener("pageLoad", (e) => {
    preventScroll.current = [
      ...e.target.querySelectorAll(".js-no-scroll-link"),
    ].map((instance) => new PreventScroll(instance, scrollPosition.current));
  });
};
