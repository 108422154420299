import { Controller } from "@hotwired/stimulus";
// import * as animations from "../utilities/animations";

export default class extends Controller {
  static targets = ["card"];

  connect() {
    console.log("hello", this.element);
  }
}
