import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="form"
export default class extends Controller {
  static targets = ["filter"];

  connect() {
    this.filterType = this.element.getAttribute("data-param").toLowerCase();
    this.params = new URLSearchParams(window.location.search);
    this.findSelected(this.filterType, this.params);
  }

  filter() {
    let newParam = this.element.querySelector(`input:checked`).value;
    if (newParam === `${this.filterType}_unset`) {
      newParam = "";
    }
    this.params.set(this.filterType, newParam);
    const formattedParams = this.params
      .toString()
      .toLowerCase()
      .replace(/\+/g, "-");
    const url = `${window.location.pathname}?${formattedParams}`;
    Turbolinks.clearCache();
    Turbolinks.visit(url);
  }

  findSelected(filterType, params) {
    // For each filter target, find any that are currently selected
    this.filterTargets.forEach((target) => {
      // check selected status of input
      const targetValue = target.querySelector("input").value.toLowerCase();
      let hasMatch = null;
      // check if active search param matches an input
      if (params.get(filterType)) {
        hasMatch = targetValue === params.get(filterType).toLowerCase();
      } else if (targetValue === `${this.filterType}_unset`) {
        hasMatch = target;
      }

      if (hasMatch) {
        target.querySelector("input").checked = true;
        target.querySelector("input").ariaChecked = true;
        target.classList.add("filter-button--selected");
        this.selectTitle = this.element.querySelector(".title-active");
        // If a dropdown with a title placehoder is present, update to match active selection
        if (this.selectTitle) {
          this.selectTitle.innerHTML =
            target.querySelector("label span").innerHTML;
        }
      }
    });
  }

  resetFilters() {
    // reset all variables expect omitted var
    const values = Array.from(this.params.keys());
    values.forEach((value) => {
      this.params.delete(value);
    });
  }
}
